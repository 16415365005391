


















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class extends Vue {
  private tab = null
  private tabs = [
    'Auth',
    'Office',
    'Users',
    // 'Quarter',
    // 'Course',
    // 'Room',
    // 'Hybrid',
    // 'Timetable',
  ]
}
